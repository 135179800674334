import React from 'react'
import { connect } from 'react-redux'
import { switchMobileMenu, showOrderWindow } from "../../store/actions";

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = ({
  switchMobileMenu, showOrderWindow
})

const PulseButton$ = (props) => {

  const orderHandler = () => {
    props.showOrderWindow()
    document.body.style.overflow = 'hidden';
  }

  return(
    <div className="globalContactsContainer" onClick={ orderHandler } title="Свяжитесь с нами">
      <div className="globalContactsBlock">
        <div className="globalContactsPulse"> </div>
        <div className="globalContactsImage"> </div>
      </div>
    </div>
  )
}

const PulseButton = connect(mapStateToProps, mapDispatchToProps)(PulseButton$)

export default PulseButton