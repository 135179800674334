import React, {useRef} from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { hideOrderWindow, postOrder } from "../../store/actions";

const mapStateToProps = (state) => ({
  isOrderShowed: state.isOrderShowed,
  isOrderSending: state.isOrderSending,
  isOrderPosted: state.isOrderPosted,
})

const mapDispatchToProps = ({
  hideOrderWindow, postOrder
})

const Order$ = (props) => {

  const hideHandler = () => {
    document.body.style.overflow = 'unset';
    props.hideOrderWindow()
  }

  const container = useRef(null)
  const name = useRef(null)
  const phone = useRef(null)
  const message = useRef(null)

  const closeHandler = (event) => {
    let elem = container.current;
    if (!elem.contains(event.target)) {
      document.body.style.overflow = 'unset';
      props.hideOrderWindow()
    }
  }

  const parsePhoneNumber = (arg) => {
    return '0' + parseInt(arg.replace(/\D+/g,""));
  }

  const postHandler = () => {

    let tel = '38' + parsePhoneNumber(phone.current.value)
    let cName = name.current.value
    if (tel.length !== 12) {
      alert('Введите корректный номер')
    } else if (cName.length === 0) {
      alert('Введите ваше имя')
    } else {
      props.postOrder({
        name: cName,
        phone: tel,
        message: message.current.value
      })
    }
  }

  return(
    <div onMouseDown={ (event) => {closeHandler(event)} }
         className="orderWrapper" style={
      props.isOrderShowed
      ?
      {top: `${window.pageYOffset}px`, opacity: '1', zIndex: '77'}
      :
      {top: `0`, opacity: '0', zIndex: '0'}
    }>
      {
        !props.isOrderPosted && !props.isOrderSending
        ?
        <div ref={container} className="orderContainer">
          <h3 className="orderTitle">Оформление заказа</h3>
          <p className="orderDescription">Вы можете заполнить форму, оставив свои данные и пожелания для вашего заказа, и наш менеджер перезвонит вам в ближайшее время.</p>
          <input ref={name} className="orderInput" type="text" placeholder="Введите ваше имя"/>

          <InputMask mask="(099)999-99-99"
                     alwaysShowMask={true}
                     ref={phone}
                     className="orderInput"
                     type="text"/>

          <textarea ref={message} className="orderInput orderWishInput" placeholder="Напишите пожелания (опционально)"/>
          <div className="cartButtonsContainer">
            <button onClick={postHandler} className="cartButton">Оформить</button>
            <button onClick={hideHandler} className="cartButton">Закрыть</button>
          </div>
        </div>
        :
        props.isOrderSending
        ?
        <div ref={container} className="orderContainer">
          <div className="loadingContainer"> </div>
        </div>
        :
        props.isOrderPosted
        ?
        <div ref={container} className="orderContainer">
          <p className="orderSuccess">Заказ успешно отправлен!<br /> Наш менеджер свяжется с вами в ближайшее время</p>
        </div>
        :
        null
      }
    </div>
  )
}

const Order = connect(mapStateToProps, mapDispatchToProps)(Order$)

export default Order
