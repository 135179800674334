import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { hideCatalogItem, pushToCart, showCatalogItem } from "../../store/actions";

const mapStateToProps = (state) => ({
  parsedData: state.parsedData,
  currentCatalogItem: state.currentCatalogItem,
  isCatalogItemShowed: state.isCatalogItemShowed,
  currentSection: state.currentSection,
})

const mapDispatchToProps = ({
  hideCatalogItem, pushToCart, showCatalogItem
})

const CatalogItem$ = (props) => {

  const container = useRef(null)
  const minContainer = useRef(null)
  const left = useRef(null)
  const right = useRef(null)

  const pushHandler = () => {
    props.pushToCart(props.currentCatalogItem)
  }

  const closeHandler = (event) => {
    let leftCheck = false
    let rightCheck = false
    let containerCheck = container.current.contains(event.target)
    let minContainerCheck = minContainer.current.contains(event.target)
    if (left.current) {
      leftCheck = left.current.contains(event.target)
    }
    if (right.current) {
      rightCheck = right.current.contains(event.target)
    }
    if (!containerCheck && !leftCheck && !rightCheck && !minContainerCheck) {
      document.body.style.overflow = 'unset';
      props.hideCatalogItem()
    }
  }

  const leftHandler = () => {
    props.showCatalogItem(props.parsedData[props.currentSection][props.currentCatalogItem.idArray - 1])
  }

  const rightHandler = () => {
    props.showCatalogItem(props.parsedData[props.currentSection][props.currentCatalogItem.idArray + 1])
  }

  return(
    <div onMouseDown={ (event) => {closeHandler(event)} }
         className="ciWrapper"
         style={
           props.isCatalogItemShowed
           ?
           {top: `${window.pageYOffset}px`, opacity: '1', zIndex: '77'}
           :
           {top: `0`, opacity: '0', zIndex: '0'}
         }>
      {
        props.currentCatalogItem.idArray !== 0
        ?
        <div ref={left} onClick={leftHandler} className="ciArrow ciLeftArrow"> </div>
        :
        null
      }
      {
        props.currentCatalogItem.idArray !== props.currentCatalogItem.length
        ?
        <div ref={right} onClick={rightHandler} className="ciArrow ciRightArrow"> </div>
        :
        null
      }

      <div onClick={closeHandler} className="ciCloseButton"> </div>
      <div ref={container} className="ciContainer">
        {
          props.currentCatalogItem.title
          ?
          <h3 className="ciTitle">
            {props.currentCatalogItem.title}
            <br/>
            {
              props.currentCatalogItem.price
              ?
              props.currentCatalogItem.price + ' грн'
              :
              null
            }
          </h3>
          :
          null
        }
        <img className="ciImage" src={`${process.env.REACT_APP_API_PATH}${props.currentCatalogItem.picture}`} alt=""/>
        {
          props.currentCatalogItem.isAdded
          ?
          <div className="ciButton">Добавлено</div>
          :
          <div onClick={pushHandler} className="ciButton">В корзину</div>
        }
        {
          props.currentCatalogItem.description
          ?
          <p className="ciDescription">{props.currentCatalogItem.description}</p>
          :
          null
        }

      </div>
      { props.currentSection &&
      <div ref={minContainer} className="ciMiniatureContainer">
        { props.parsedData[props.currentSection].map( (item, index) => {
          return(
            <div key={item.id} onClick={ () => props.showCatalogItem(props.parsedData[props.currentSection][index]) }
                 style={ props.currentCatalogItem.idArray === index ? {opacity: '0.3'} : {} }
                 className="ciMiniatureItem">
              <img className="ciMiniatureImage" src={`${process.env.REACT_APP_API_PATH}${item.picture}`} alt=""/>
            </div>
          )
        } ) }
      </div>
      }
    </div>
  )
}

const CatalogItem = connect(mapStateToProps, mapDispatchToProps)(CatalogItem$)

export default CatalogItem
