import { put, takeLatest, all } from 'redux-saga/effects';
import {
  GET_DATA,
  DATA_RECEIVED,
  POST_ORDER,
  ORDER_POSTED,
  POST_CART_ORDER,
  CART_ORDER_POSTED,
} from "./constants";

const API_PATH = process.env.REACT_APP_API_PATH;

function* getCatalog() {
  let data = yield fetch(`${API_PATH}catalog`)
    .then( response => response.json() );
  yield put({ type: DATA_RECEIVED, data: data });
}

function* postOrder(action) {
  let info = action.info
  let options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(info)
  }
  let data = yield fetch(`${API_PATH}order`, options)
    .then( response => response.json() )
  yield put({ type: ORDER_POSTED, data: data });
}

function* postCartOrder(action) {
  let info = action.info
  let options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(info)
  }
  let data = yield fetch(`${API_PATH}cart`, options)
    .then( response => response.json() )
  yield put({ type: CART_ORDER_POSTED, data: data });
}



function* getCatalogWatcher() {
  yield takeLatest(GET_DATA, getCatalog)
}
function* postOrderWatcher() {
  yield takeLatest(POST_ORDER, postOrder)
}
function* postCartOrderWatcher() {
  yield takeLatest(POST_CART_ORDER, postCartOrder)
}



export default function* rootSaga() {
  yield all([
    getCatalogWatcher(),
    postOrderWatcher(),
    postCartOrderWatcher()
  ])
}
