import React from 'react';
import { connect } from 'react-redux';
import {
  showOrderWindow, resetAll, showCart, setCatalogSection, switchMobileMenu, switchAboutWindow, switchServicesWindow
} from "../../store/actions";

const mapStateToProps = (state) => ({
  contentPosition: state.contentPosition,
  cart: state.cart,
  isMobileMenuShowed: state.isMobileMenuShowed,
})

const mapDispatchToProps = ({
  showOrderWindow, resetAll, showCart, setCatalogSection, switchMobileMenu, switchAboutWindow, switchServicesWindow
})

const Menu$ = (props) => {

  const mainPageHandler = () => {
    props.switchMobileMenu()
    window.scroll({ top: 0, behavior: "smooth" })
    props.resetAll()
  }

  const catalogHandler = () => {
    props.switchMobileMenu()
    props.setCatalogSection(null)
    window.scroll({ top: props.contentPosition, behavior: "smooth" })
  }

  const orderHandler = () => {
    props.switchMobileMenu()
    props.showOrderWindow()
    document.body.style.overflow = 'hidden';
  }

  const cartHandler = () => {
    props.switchMobileMenu()
    props.showCart()
    document.body.style.overflow = 'hidden';
  }

  const aboutHandler = () => {
    props.switchMobileMenu()
    props.switchAboutWindow()
    document.body.style.overflow = 'hidden';
  }

  const servicesHandler = () => {
    props.switchMobileMenu()
    props.switchServicesWindow()
    document.body.style.overflow = 'hidden';
  }

  const contactsHandler = () => {
    props.switchMobileMenu()
    window.scroll({ top: 99999, behavior: "smooth" })
  }

  return(
    <>
      <div className="mainMenu">
        <div className="mainMenuLogoContainer">
          <div className="mainMenuLogo"> </div>
          <div onClick={ mainPageHandler } className="mainMenuLogoTitle">Мебель<br /> под заказ</div>
        </div>
        <div className="mainMenuLeftContainer">
          <div onClick={ mainPageHandler } className="mainMenuPosition">Главная</div>
          <div onClick={ catalogHandler } className="mainMenuPosition">Каталог</div>
          <div onClick={ orderHandler } className="mainMenuPosition">Заказать</div>
          <div onClick={ servicesHandler } className="mainMenuPosition">Услуги</div>
          <div onClick={ aboutHandler } className="mainMenuPosition">О нас</div>
        </div>
        <div className="mainMenuRightContainer">

          <div onClick={ contactsHandler } className="mainMenuPosition">Контакты</div>
          <div onClick={ cartHandler } className="mainMenuPosition">
            Корзина {props.cart.length ? `(${props.cart.length})` : null}
          </div>
        </div>
        <div className="mainMenuPhone">
          <div className="mmpPhone"><a href="tel:+380980744755">+38 (098) 0-744-755</a></div>
          <div className="mmpPhone"><a href="tel:+380730744755">+38 (073) 0-744-755</a></div>
        </div>
      </div>


      <div className="mainMenuMobile" style={ props.isMobileMenuShowed ? {flexDirection: 'column-reverse', height: 'auto'} : {flexDirection: 'row'} }>

        <div onClick={props.switchMobileMenu}
             className={ props.isMobileMenuShowed ? 'mobileMenuButton mobileMenuButtonClose' : 'mobileMenuButton mobileMenuButtonOpen' }>
          { props.isMobileMenuShowed ? 'Закрыть' : 'Меню' }
        </div>

        <div className="mainMenuPhone" style={ !props.isMobileMenuShowed ? {display: 'flex', flexDirection: 'column'} : {display: 'none'} }>
          <div className="mmpPhone"><a href="tel:+380980744755">+38 (098) 0-744-755</a></div>
          <div className="mmpPhone"><a href="tel:+380730744755">+38 (073) 0-744-755</a></div>
        </div>

        <div className="mobileMenuContainer" style={ props.isMobileMenuShowed ? {display: 'flex'} : {display: 'none'} }>
          <div className="mainMenuLogoContainer">
            <div className="mainMenuLogo">

            </div>
            <div onClick={ mainPageHandler } className="mainMenuLogoTitle">Мебель<br /> под заказ</div>
          </div>
          <div onClick={ mainPageHandler } className="mainMenuPosition">Главная</div>
          <div onClick={ catalogHandler } className="mainMenuPosition">Каталог</div>
          <div onClick={ orderHandler } className="mainMenuPosition">Заказать</div>
          <div onClick={ contactsHandler } className="mainMenuPosition">Контакты</div>
          <div onClick={ servicesHandler } className="mainMenuPosition">Услуги</div>
          <div onClick={ aboutHandler } className="mainMenuPosition">О нас</div>
          <div onClick={ cartHandler } className="mainMenuPosition">
            Корзина {props.cart.length ? `(${props.cart.length})` : null}
          </div>
        </div>

      </div>

    </>
  )
}

const Menu = connect(mapStateToProps, mapDispatchToProps)(Menu$)

export default Menu
