import React, { useRef } from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { removeFromCart, hideCart, acceptCartOrder, dismissCartOrder, postCartOrder } from "../../store/actions";

const mapStateToProps = (state) => ({
  isCartShowed: state.isCartShowed,
  cart: state.cart,
  isCartAccepted: state.isCartAccepted,
  isCartSending: state.isCartSending,
  isCartPosted: state.isCartPosted,
})

const mapDispatchToProps = ({
  removeFromCart, hideCart, acceptCartOrder, dismissCartOrder, postCartOrder
})

const Cart$ = (props) => {

  const container = useRef(null)
  const name = useRef(null)
  const phone = useRef(null)

  const closeHandler = (event) => {
    let elem = container.current;
    if (!elem.contains(event.target)) {
      document.body.style.overflow = 'unset';
      props.hideCart()
    }
  }

  const parsePhoneNumber = (arg) => {
    return '0' + parseInt(arg.replace(/\D+/g,""));
  }

  const hideHandler = () => {
    document.body.style.overflow = 'unset';
    props.hideCart()
  }

  const postHandler = () => {

    let tel = '38' + parsePhoneNumber(phone.current.value)
    let cName = name.current.value
    let list = localStorage.getItem('cart')

    if (tel.length !== 12) {
      alert('Введите корректный номер')
    } else if (cName.length === 0) {
      alert('Введите ваше имя')
    } else {
      props.postCartOrder({
        name: cName,
        phone: tel,
        list: list
      })
    }


  }

  return(
    <div onMouseDown={ (event) => {closeHandler(event)} }
         className="cartWrapper"
         style={
            props.isCartShowed
            ?
            {top: `${window.pageYOffset}px`, opacity: '1', zIndex: '77'}
            :
            {top: `0`, opacity: '0', zIndex: '0'}
    }>
      <div ref={container} className="cartContainer">

        {
          props.cart.length !== 0
          ?
          <>
            {
              !props.isCartAccepted
              ?
              <>
                <h3 className="cartTitle">Корзина</h3>
                <div className="cartItemsContainer">
                  { props.cart.map( item => {
                    return(
                      <div className="cartItemBlock" key={item.id}>
                        <div className="cibPicture"
                             style={{ backgroundImage: `url("${process.env.REACT_APP_API_PATH}${item.picture}") ` }}> </div>
                        <h3 className="cibTitle">{item.title}</h3>
                        <p className="cibTitle cibDesc">{item.description}</p>
                        <p className="cibTitle">{item.price} грн</p>
                        <button onClick={ () => props.removeFromCart(item) } className="cibRemoveButton">X</button>
                      </div>
                    )
                  } ) }
                </div>
                <div className="cartButtonsContainer">
                  <button onClick={props.acceptCartOrder} className="cartButton">Заказать</button>
                  <button onClick={ hideHandler } className="cartButton">Вернуться</button>
                </div>
              </>
              :
              <>
                {
                  !props.isCartSending && !props.isCartPosted
                  ?
                  <>
                    <h3 className="orderTitle">Оформление заказа</h3>
                    <p className="orderDescription">Пожалуйста, оставьте свои данные и наш менеджер перезвонит вам в ближайшее время.</p>
                    <input ref={name} className="orderInput" type="text" placeholder="Введите ваше имя"/>
                    <InputMask mask="(099)999-99-99"
                               alwaysShowMask={true}
                               ref={phone}
                               className="orderInput"
                               type="text"/>
                    <div className="cartButtonsContainer" style={{ marginTop: '20px' }}>
                      <button onClick={postHandler} className="cartButton">Заказать</button>
                      <button onClick={props.dismissCartOrder} className="cartButton">Вернуться</button>
                    </div>
                  </>
                  :
                  props.isCartSending
                  ?
                  <div className="loadingContainer"> </div>
                  :
                  props.isCartPosted
                  ?
                  <p className="orderSuccess">Заказ успешно отправлен!<br /> Наш менеджер свяжется с вами в ближайшее время</p>
                  :
                  null
                }
              </>
            }
          </>
          :
          <>
            <div className="cartTitle">Корзина пуста</div>
            <button onClick={ hideHandler } className="cartButton">Вернуться</button>
          </>
        }
      </div>
    </div>
  )
}

const Cart = connect(mapStateToProps, mapDispatchToProps)(Cart$)

export default Cart
