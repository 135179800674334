import React, {useRef} from 'react';
import {connect} from 'react-redux'
import {switchAboutWindow} from "../../store/actions";

const mapStateToProps = (state) => ({
  isAboutShowed: state.isAboutShowed
})

const mapDispatchToProps = ({
  switchAboutWindow
})

const About$ = (props) => {

  const container = useRef(null)

  const closeHandler = (event) => {
    let elem = container.current;
    if (!elem.contains(event.target)) {
      document.body.style.overflow = 'unset';
      props.switchAboutWindow()
    }
  }

  const hideHandler = () => {
    document.body.style.overflow = 'unset';
    props.switchAboutWindow()
  }

  return (
    <div onMouseDown={(event) => {
      closeHandler(event)
    }}
         className="orderWrapper" style={
      props.isAboutShowed
        ?
        {top: `${window.pageYOffset}px`, opacity: '1', zIndex: '77'}
        :
        {top: `0`, opacity: '0', zIndex: '0'}
    }>
      <div ref={container} className="orderContainer">
        <h3 className="orderTitle">О нас</h3>
        <div className="servicesContainer">

          <p className="orderDescription aboutDescription">
            Наша компания - это коллектив профессионалов мебельной отрасли с более чем пятнадцатилетним стажем. Наша компания зарекомендовала себя на украинском пространстве, как ответственная организация, для которой качество изготовлены продукции занимает Первое место в процессе производства.<br/><br/>
            Наши изделия предназначены для широкого круга покупателей, как для домашнего использования, так и для офисного и магазинного. Индивидуальный подход к каждому клиенту дает возможность точно воплотить в жизнь все пожелания и сделать Вашу жизнь комфортной.<br/><br/>
            Постоянные инновации в отрасли мебельного производства позволяют предложить вам широкий выбор материалов и фурнитуры. А это, в свою очередь, позволяет нам обеспечить лучшее соотношение цены и качества.<br/><br/>
            Спасибо Вам, что выбираете нас. Наша компания сделает все возможное, чтобы Вы стали нашими постоянными клиентом и могли порекомендовать нас своим знакомым.
          </p>

        </div>
        <button onClick={hideHandler} className="cartButton">Закрыть</button>
      </div>
    </div>
  )
}

const About = connect(mapStateToProps, mapDispatchToProps)(About$)

export default About
