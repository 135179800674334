import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = ({

})

const Footer$ = (props) => {
  return(
    <div className="footer">
      <div className="footerContactsContainer">
        <div className="footerContactsTitle">Свяжитесь с нами</div>
        <div className="footerContacts">
          <div className="footerContactsItem">
            <a href="tel:+380980744755" className="fciLink">+38 (098) 0-744-755</a>
            <a href="tel:+380980744755" className="fciLink">+38 (073) 0-744-755</a>
          </div>
          <a href="mailto:mebel.kh@gmail.com"><div className="footerContactsItem fciLink">mebel.kh@gmail.com</div></a>

          <div className="footerContactsItem fciTime">пн-вс: 8:00 - 18:00</div>
        </div>
      </div>

      <div className="footerInstagram">
        <a href="https://www.instagram.com/mebel_kh_com/" target="_blank" rel="noreferrer"><div className="footerInstagramTitle">Подписывайтесь на наш Instagram</div></a>
        <div className="footerInstagramPhotos">
          <a href="https://www.instagram.com/mebel_kh_com/" target="_blank" rel="noreferrer"><div className="fipItem fip1"> </div></a>
          <a href="https://www.instagram.com/mebel_kh_com/" target="_blank" rel="noreferrer"><div className="fipItem fip2"> </div></a>
          <a href="https://www.instagram.com/mebel_kh_com/" target="_blank" rel="noreferrer"><div className="fipItem fip3"> </div></a>
          <a href="https://www.instagram.com/mebel_kh_com/" target="_blank" rel="noreferrer"><div className="fipItem fip4"> </div></a>
        </div>
      </div>
    </div>
  )
}

const Footer = connect(mapStateToProps, mapDispatchToProps)(Footer$)

export default Footer
