import React from 'react';
import {connect} from 'react-redux';
import {
  setCatalogSection, showCatalogItem, pushToCart
} from "../../store/actions";

const mapStateToProps = (state) => ({
  parsedData: state.parsedData,
  sections: state.sections,
  currentSection: state.currentSection,
})

const mapDispatchToProps = ({
  setCatalogSection, showCatalogItem, pushToCart
})

const CatalogPage$ = (props) => {

  const itemHandler = (item) => {
    props.showCatalogItem(item)
    document.body.style.overflow = 'hidden';
  }

  return (
    <div className="catalogPageContainer" style={
      props.currentSection !== null
        ?
        {top: `${window.pageYOffset}px`, opacity: '1', zIndex: '77', height: 'auto'}
        :
        {top: `${window.pageYOffset}px`, opacity: '0', zIndex: '0', height: '0'}
    }>

      {
        props.currentSection
        ?
        props.parsedData[props.currentSection].map(item => {
          return (
            <div className="catalogMainBlock" key={item.picture} >
              <div onClick={ () => itemHandler(item) } className="catalogMainItem">
                <div className="cmiBackground" style={{backgroundImage: `url("${process.env.REACT_APP_API_PATH}${item.picture}") `}}> </div>
                <div className="cmiContent">
                  {item.title}
                  {
                    item.price
                    ?
                    <>
                      <br/>
                      <p style={{ fontWeight: '200' }}>{ item.price } грн</p>
                    </>
                    :
                    null
                  }

                </div>

              </div>
              {
                item.isAdded
                ?
                <div className="cmiAddButton">Добавлено</div>
                :
                <div onClick={ () => props.pushToCart(item) } className="cmiAddButton">В корзину</div>
              }

            </div>
          )
        })
        :
        null
      }

      <button className="catalogMainBlock catalogBackButton" onClick={ () => props.setCatalogSection(null) }>Вернуться к разделам</button>

    </div>
  )
}

const CatalogPage = connect(mapStateToProps, mapDispatchToProps)(CatalogPage$)

export default CatalogPage