import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import Catalog from "../Catalog/Catalog";
import Menu from "./Menu";
import Header from "./Header";
import Footer from "./Footer";
import Order from "../Order/Order";
import Cart from "../Cart/Cart";
import About from "../About/About";
import Services from "../Services/Services";
import CatalogItem from "../Catalog/CatalogItem";
import PulseButton from "../PulseButton/PulseButton";
import { calcContentPosition, getData, checkCart } from "../../store/actions";

const mapStateToProps = (state) => ({
  currentCatalogItem: state.currentCatalogItem,
})

const mapDispatchToProps = ({
  calcContentPosition, getData, checkCart
})

const Layout$ = (props) => {

  useEffect( () => {
    // props.calcContentPosition(catalog.current.offsetTop)
    props.getData()
    function waitForData() {
      props.checkCart()
    }
    setTimeout(waitForData, 1000)
  }, [] )   // eslint-disable-line

  const catalog = useRef(null)

  return (
    <>
      <div className="gWrapper">
        <PulseButton />
        <Order />
        { props.currentCatalogItem ? <CatalogItem /> : null }

        <Services />
        <Cart />
        <About />
        <Menu />
        <Header />
        <div className="mainContent" ref={catalog}>
          <Catalog />
        </div>
        <Footer />
      </div>
    </>
  )
}

const Layout = connect(mapStateToProps, mapDispatchToProps)(Layout$)

export default Layout
