import {
  GET_DATA,
  // PARSE_DATA,
  SET_CATALOG_SECTION,
  CALC_CONTENT_POSITION,
  SHOW_ORDER_WINDOW,
  HIDE_ORDER_WINDOW,
  SHOW_CATALOG_ITEM,
  HIDE_CATALOG_ITEM,
  RESET_ALL,
  PUSH_TO_CART,
  CHECK_CART,
  SHOW_CART,
  HIDE_CART,
  REMOVE_FROM_CART,
  POST_ORDER,
  POST_CART_ORDER,
  ACCEPT_CART_ORDER,
  DISMISS_CART_ORDER,
  SWITCH_MOBILE_MENU,
  SWITCH_ABOUT_WINDOW,
  SWITCH_SERVICES_WINDOW,
} from './constants'

export const getData = () => ({
  type: GET_DATA
})
// export const parseData = () => ({
//   type: PARSE_DATA
// })
export const setCatalogSection = (section) => ({
  type: SET_CATALOG_SECTION, section
})
export const calcContentPosition = (position) => ({
  type: CALC_CONTENT_POSITION, position
})
export const showOrderWindow = () => ({
  type: SHOW_ORDER_WINDOW
})
export const hideOrderWindow = () => ({
  type: HIDE_ORDER_WINDOW
})
export const showCatalogItem = (item) => ({
  type: SHOW_CATALOG_ITEM, item
})
export const hideCatalogItem = () => ({
  type: HIDE_CATALOG_ITEM
})
export const resetAll = () => ({
  type: RESET_ALL
})
export const pushToCart = (item) => ({
  type: PUSH_TO_CART, item
})
export const checkCart = () => ({
  type: CHECK_CART
})
export const showCart = () => ({
  type: SHOW_CART
})
export const hideCart = () => ({
  type: HIDE_CART
})
export const removeFromCart = (item) => ({
  type: REMOVE_FROM_CART, item
})
export const postOrder = (info) => ({
  type: POST_ORDER, info
})
export const postCartOrder = (info) => ({
  type: POST_CART_ORDER, info
})
export const acceptCartOrder = () => ({
  type: ACCEPT_CART_ORDER
})
export const dismissCartOrder = () => ({
  type: DISMISS_CART_ORDER
})
export const switchMobileMenu = () => ({
  type: SWITCH_MOBILE_MENU
})
export const switchAboutWindow = () => ({
  type: SWITCH_ABOUT_WINDOW
})
export const switchServicesWindow = () => ({
  type: SWITCH_SERVICES_WINDOW
})
