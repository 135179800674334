import React, {useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import CatalogPage from "./CatalogPage";
import { setCatalogSection, showOrderWindow, calcContentPosition } from "../../store/actions";

const mapStateToProps = (state) => ({
  sections: state.sections,
  currentSection: state.currentSection,
})

const mapDispatchToProps = ({
  setCatalogSection, showOrderWindow, calcContentPosition
})

const Catalog$ = (props) => {

  const catalog = useRef(null)

  useEffect( () => {
    console.log(catalog.current.offsetTop);
    props.calcContentPosition(catalog.current.offsetTop - 70)
  }, [] )   // eslint-disable-line

  const orderHandler = () => {

    props.showOrderWindow()
    document.body.style.overflow = 'hidden';
  }

  return(
    <div className="catalogContainer">
      <div onClick={ orderHandler } className="catalogTitleContainer">
        <div className="ctcBackground"> </div>
        <div className="ctcContent">
          <div className="ctcTitle">Спроектируйте свою мечту</div>
          <div className="ctcDescription">Избавьте себя от труднойстей выбора и закажите индвидуальный дизайн, который подходит именно вам! Вы можете составить заказ и наш менеджер свяжется с вами в ближайшее время.</div>
        </div>
      </div>



        <CatalogPage />

        <div ref={catalog} className="catalogMainContainer" style={
          props.currentSection === null
            ?
            {top: `${window.pageYOffset}px`, opacity: '1', zIndex: '77', height: 'auto'}
            :
            {top: `${window.pageYOffset}px`, opacity: '0', zIndex: '0', height: '0'}
        }>
          { props.sections.map( item => {
            return(
              <div className="catalogMainBlock" key={item.id}>
                <div onClick={ () => props.setCatalogSection(item.idSection) } className="catalogMainItem">
                  <div className="cmiBackground" style={{ backgroundImage: `url("${process.env.REACT_APP_API_PATH}${item.picture}") ` }}> </div>
                  <div className="cmiContent">
                    {item.section}
                  </div>
                </div>
              </div>
            )
          } ) }
        </div>

    </div>
  )
}

const Catalog = connect(mapStateToProps, mapDispatchToProps)(Catalog$)

export default Catalog