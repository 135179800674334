import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = ({

})

const Header$ = (props) => {
  return(
    <div className="mainHeader">
      <div className="mainHeaderTitleContainer">
        <h1 className="mainHeaderTitle">Мебель от производителя в Харькове</h1>
        <p className="mainHeaderDescription">Мебель в ассортименте и под заказ для любых нужд</p>
      </div>

    </div>
  )
}

const Header = connect(mapStateToProps, mapDispatchToProps)(Header$)

export default Header