import {
  GET_DATA,
  DATA_RECEIVED,
  // PARSE_DATA,
  SET_CATALOG_SECTION,
  CALC_CONTENT_POSITION,
  SHOW_ORDER_WINDOW,
  HIDE_ORDER_WINDOW,
  SHOW_CATALOG_ITEM,
  HIDE_CATALOG_ITEM,
  RESET_ALL,
  PUSH_TO_CART,
  CHECK_CART,
  SHOW_CART,
  HIDE_CART,
  REMOVE_FROM_CART,
  POST_ORDER,
  ORDER_POSTED,
  POST_CART_ORDER,
  CART_ORDER_POSTED,
  ACCEPT_CART_ORDER,
  DISMISS_CART_ORDER,
  SWITCH_MOBILE_MENU,
  SWITCH_ABOUT_WINDOW,
  SWITCH_SERVICES_WINDOW,
} from './constants'

const initState = {
  data: [],
  parsedData: [],
  sections: [],
  currentSection: null,
  contentPosition: null,
  isAboutShowed: false,
  isServicesShowed: false,
  isOrderShowed: false,
  isCatalogItemShowed: false,
  isCartShowed: false,
  isCartSending: false,
  isCartPosted: false,
  isCartAccepted: false,
  isOrderSending: false,
  isOrderPosted: false,
  isMobileMenuShowed: false,
  currentCatalogItem: null,
  cart: [],
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_DATA:
      return state

    case DATA_RECEIVED:
      let drParsed = {}
      let drSections = []
      action.data.map( item => {
        item.isAdded = false
        let element = item.idSection
        if (!drParsed[element]) {
          drParsed[element] = []
          drSections.push(item)
        }
        drParsed[element].push(item)
        return null
      } )

      for (let key in drParsed) {
        for (let i = 0; i < drParsed[key].length; i++) {
          drParsed[key][i].idArray = i;
          drParsed[key][i].length = drParsed[key].length - 1;
        }
      }

      return { ...state, data: action.data, parsedData: drParsed, sections: drSections }

    // case PARSE_DATA:
    //   let pdParsed = {}
    //   let pdSections = []
    //   state.data.map( item => {
    //     let element = item.idSection
    //     if (!pdParsed[element]) {
    //       pdParsed[element] = []
    //       pdSections.push(item)
    //     }
    //     pdParsed[element].push(item)
    //     return null
    //   } )
    //   console.log(pdParsed);
    //   console.log(pdSections);
    //   return { ...state, parsedData: pdParsed, sections: pdSections }

    case SET_CATALOG_SECTION:
      return { ...state, currentSection: action.section }

    case CALC_CONTENT_POSITION:
      return { ...state, contentPosition: action.position }

    case SHOW_ORDER_WINDOW:
      return { ...state, isOrderShowed: true }

    case HIDE_ORDER_WINDOW:
      return { ...state, isOrderShowed: false }

    case SHOW_CATALOG_ITEM:
      return { ...state, currentCatalogItem: action.item, isCatalogItemShowed: true }

    case HIDE_CATALOG_ITEM:
      return { ...state, isCatalogItemShowed: false }

    case RESET_ALL:
      return { ...state, currentSection: null }

    case PUSH_TO_CART:
      let ptcCart = state.cart.slice()
      let ptcItem = action.item
      let ptcData = Object.assign({}, state.parsedData)
      let ptcCurrent = Object.assign({}, state.currentCatalogItem)
      for (let key in ptcData) {
        for (let i = 0; i < ptcData[key].length; i++) {
          if (+ptcItem.id === +ptcData[key][i].id) {
            ptcData[key][i].isAdded = true;
            ptcCurrent.isAdded = true;
          }
        }
      }
      ptcCart.push(ptcItem)
      let ptcStorageString = ''
      ptcCart.map( item => {
        ptcStorageString += `${item.id},`
        return null
      } )
      localStorage.clear()
      localStorage.setItem('cart', ptcStorageString)
      return { ...state, cart: ptcCart, currentCatalogItem: ptcCurrent, parsedData: ptcData }

    case CHECK_CART:
      let ccStorageString = localStorage.getItem('cart')
      let ccCart = []
      let ccData = Object.assign({}, state.parsedData)
      if (ccStorageString) {
        let ccCodes = ccStorageString.split(',').slice(0, -1)
        for (let key in ccData) {
          for (let i = 0; i < ccData[key].length; i++) {
            for (let j = 0; j < ccCodes.length; j++) {
              if (+ccCodes[j] === +ccData[key][i].id) {
                ccData[key][i].isAdded = true;
                ccCart.push(ccData[key][i])
                break
              }
            }
          }
        }
      }
      return { ...state, cart: ccCart, parsedData: ccData }

    case SHOW_CART:
      return { ...state, isCartShowed: true }

    case HIDE_CART:
      return { ...state, isCartShowed: false }

    case REMOVE_FROM_CART:
      let rfcCart = state.cart.slice()
      let rfcData = Object.assign({}, state.parsedData)
      let rfcId = +action.item.id
      for (let key in rfcData) {
        for (let i = 0; i < rfcData[key].length; i ++) {
          if (+rfcData[key][i].id === rfcId) {
            rfcData[key][i].isAdded = false
          }
        }
      }
      for (let i = rfcCart.length - 1; i >= 0; i--) {
        if (+rfcCart[i].id === rfcId) {
          rfcCart.splice(i, 1)
        }
      }
      let rfcStorageString = ''
      rfcCart.map( item => {
        rfcStorageString += `${item.id},`
        return null
      } )
      localStorage.clear()
      localStorage.setItem('cart', rfcStorageString)
      return { ...state, cart: rfcCart, parsedData: rfcData }

    case POST_ORDER:
      return { ...state, isOrderSending: true}

    case ORDER_POSTED:
      return { ...state, isOrderSending: false, isOrderPosted: true }

    case POST_CART_ORDER:
      return { ...state, isCartSending: true }

    case CART_ORDER_POSTED:
      localStorage.clear()
      return { ...state, isCartSending: false, isCartPosted: true }

    case ACCEPT_CART_ORDER:
      return { ...state, isCartAccepted: true }

    case DISMISS_CART_ORDER:
      return { ...state, isCartAccepted: false }

    case SWITCH_MOBILE_MENU:
      console.log(state.isMobileMenuShowed);
      return { ...state, isMobileMenuShowed: !state.isMobileMenuShowed }

    case SWITCH_ABOUT_WINDOW:
      return { ...state, isAboutShowed: !state.isAboutShowed }

    case SWITCH_SERVICES_WINDOW:
      return { ...state, isServicesShowed: !state.isServicesShowed }



    default:
      return state
  }
}

export default reducer
