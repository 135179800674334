export const GET_DATA = 'GET_DATA';
export const DATA_RECEIVED = 'DATA_RECEIVED';
// export const PARSE_DATA = 'PARSE_DATA';
export const SET_CATALOG_SECTION = 'SET_CATALOG_SECTION';
export const CALC_CONTENT_POSITION = 'CALC_CONTENT_POSITION';
export const SHOW_ORDER_WINDOW = 'SHOW_ORDER_WINDOW';
export const HIDE_ORDER_WINDOW = 'HIDE_ORDER_WINDOW';
export const SHOW_CATALOG_ITEM = 'SHOW_CATALOG_ITEM';
export const HIDE_CATALOG_ITEM = 'HIDE_CATALOG_ITEM';
export const RESET_ALL = 'RESET_ALL';
export const PUSH_TO_CART = 'PUSH_TO_CART';
export const CHECK_CART = 'CHECK_CART';
export const SHOW_CART = 'SHOW_CART';
export const HIDE_CART = 'HIDE_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const POST_ORDER = 'POST_ORDER';
export const ORDER_POSTED = 'ORDER_POSTED';
export const POST_CART_ORDER = 'POST_CART_ORDER';
export const CART_ORDER_POSTED = 'CART_ORDER_POSTED';
export const ACCEPT_CART_ORDER = 'ACCEPT_CART_ORDER';
export const DISMISS_CART_ORDER = 'DISMISS_CART_ORDER';
export const SWITCH_MOBILE_MENU = 'SWITCH_MOBILE_MENU';
export const SWITCH_ABOUT_WINDOW = 'SWITCH_ABOUT_WINDOW';
export const SWITCH_SERVICES_WINDOW = 'SWITCH_SERVICES_WINDOW';
