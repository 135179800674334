import React, {useRef} from 'react';
import {connect} from 'react-redux'
import {switchServicesWindow} from "../../store/actions";

const mapStateToProps = (state) => ({
  isServicesShowed: state.isServicesShowed
})

const mapDispatchToProps = ({
  switchServicesWindow
})

const Services$ = (props) => {

  const container = useRef(null)

  const closeHandler = (event) => {
    let elem = container.current;
    if (!elem.contains(event.target)) {
      document.body.style.overflow = 'unset';
      props.switchServicesWindow()
    }
  }

  const hideHandler = () => {
    document.body.style.overflow = 'unset';
    props.switchServicesWindow()
  }

  return (
    <div onMouseDown={(event) => {
      closeHandler(event)
    }}
         className="orderWrapper servicesWrapper" style={
      props.isServicesShowed
        ?
        {top: `${window.pageYOffset}px`, opacity: '1', zIndex: '77'}
        :
        {top: `0`, opacity: '0', zIndex: '0'}
    }>
      <div ref={container} className="orderContainer">
        <div className="servicesContainer">
          <h3 className="orderTitle">Предварительный просчет</h3>
          <p className="orderDescription">
            Элемент сервиса, который позволяет предварительно оценить стоимость Вашего проекта (изделия).
            Наш дизайнер связывается с Вами для уточнения деталей и делает расчет с учетом Ваших пожеланий.На основании суммы предварительного просчета Вы принимаете решение о размещении заказа на нашем производстве. При этом, дизайнер подскажет наиболее оптимальные для Вас варианты его реализации.
            Таким образом Вы достаточно точно можете понимать стоимость вашей мебели, для дальнейшего вызова нашего технолога и обмере помещения.
          </p>
          <h3 className="orderTitle">Замер помещения</h3>
          <p className="orderDescription">
            Наша компания предлагает услугу замера мебели и помещения для дальнейшей установки любой комбинации модельной мебели, изготовленной по вашему заказу.
            Для чего делается замер мебели? Стены и полы в квартире, часто даже после ремонта, имеют существенные отклонения от стопроцентно правильной геометрии. При разработке проекта все отклонения должны быть подробно учтены. В противном случае, монтаж мебели может быть проблематичным. Совершенно ясно, что заказчик, скорее всего, не сможет самостоятельно составить и предоставить чёткие и точные данные. Это касается мебели для кухонь и шкафов купе. А для дальнейшей разработки проекта мебели, конструкторам-технологам нужны именно замеры, выполненные профессионально.
            Кто выполняет замеры мебели? Несмотря на кажущуюся простоту, замеры мебели — это операция достаточно сложная и трудозатратная. Обычно на неё требуется 1-1,5 часа. Замеры помещения выполняет технолог, который впоследствии будет заниматься разработкой технологического чертежа вашей мебели. Именно поэтому на него и возлагается ответственность за достоверность всех размеров.
          </p>
          <h3 className="orderTitle">Дизайн-проект</h3>
          <p className="orderDescription">
            У Вас нет окончательного видения, как должна выглядеть новая мебель? Или Вы хотите получить новый альтернативный вариант. Наш дизайнер подберет для Вас несколько интересных решений, одно из которых удовлетворит Ваш изысканный вкус.
            Дизайн-проект будет содержать не только 3-D визуализацию нашего предложения, но и полный список материалов и фурнитуры, которые потребуются для производства.
            Стоимость подготовки дизайн-проекта будет в дальнейшем вычтена из общей суммы заказа на производство изделий, при условии его размещения на нашем производстве.
            Таким образом, услуга подготовки дизайн-проекта для наших клиентов является абсолютно бесплатной!
          </p>
          <h3 className="orderTitle">Доставка мебели</h3>
          <p className="orderDescription">
            Доставка мебели осуществляется специально подготовленным грузовым автомобилем. Оборудованным для транспортировки мебели различных габаритов и при любых погодных условиях.
            Доставка мебели по Украине рассчитывывается и оговариется в индивидуальном порядке с нашим дизайнером, и осуществляется компанией-перевозчиком. Ваша мебель упаковывается в специальный, ударопрочный материал, для безопасной транспортировки.
          </p>
          <h3 className="orderTitle">Сборка</h3>
          <p className="orderDescription">
            Наши сборщики-монтажники — это настоящие мастера своего дела. Наличие высоко-технологичного инструмента позволяет им быстро и без нареканий собрать и установить изделия. Которые были изготовлены на нашем производстве. Они ценят Ваше и своё время. Поэтому процесс сборки займет ровно столько времени, сколько необходимо.
            Сборщики-монтажники правильно соберут и установят изделия, что гарантирует их долгий срок службы. после сборки ребята всегда уберут за собой мусор и отходы, которые остались после установки.
          </p>
        </div>
        <button onClick={hideHandler} className="cartButton">Закрыть</button>
      </div>
    </div>
  )
}

const Services = connect(mapStateToProps, mapDispatchToProps)(Services$)

export default Services
